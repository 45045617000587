import React, { useState } from "react";
import { Offcanvas, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import "./Menu.css";
import "./iconsmind-s/css/iconsminds.css";
import { Col, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { Link } from "react-router-dom";

const MenuOffcanvas = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* Botão para abrir o menu */}
      <Button variant="" className="botao-menu-mobile sumir-desktop" onClick={handleShow}>
        <FontAwesomeIcon icon={faBars} />
        <br></br>
         <span >Menu</span>
      </Button>

      {/* Menu Offcanvas */}
      <Offcanvas show={show} onHide={handleClose}>
        
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
        
            <ul className="list-unstyled">

              <li>
                <Link as={Link} to="/" className="menu-item">
                  <i className="iconsminds-dashboard"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li>
                <DropdownButton
                  id="dropdown-clientes"
                  title={
                    <>
                      <i className="iconsminds-user"></i>
                      <span>Clientes</span>
                    </>
                  }
                  drop="down"
                  variant="link"
                  className="menu-item  dropdown-clientes"
                >
                  <Dropdown.Item as={Link} to="/cadastroCliente"><i className="iconsminds-add-user"></i> Cadastro</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/pontuarCliente"><i className="iconsminds-add-user"></i> Pontuar</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/cadastradosClientes"><i className="iconsminds-conference"></i> Clientes Cadastrados</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/pontosDistribuidos"><i className="iconsminds-heart"></i> Pontos Distribídos</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/aniversariantesClientes"><i className="iconsminds-gift-box"></i> Aniversariante do Mês</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/statusIndicacoes"><i className="iconsminds-user"></i> Status de Indicação</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/cliente" target="_blank"><i className="iconsminds-user"></i> Página do Cliente</Dropdown.Item>
                </DropdownButton>
              </li>


              <li>
                <DropdownButton
                  id="dropdown-clientes"
                  title={
                    <>
                      <i className="iconsminds-target-market"></i>
                      <span>Vendedor</span>
                    </>
                  }
                  drop="down"
                  variant="link"
                  className="menu-item  dropdown-clientes"
                >
                <Dropdown.Item as={Link} to="/vendedor"><i className="iconsminds-user"></i> Vendedores</Dropdown.Item>
                <Dropdown.Item as={Link} to="/vendedor/cadastro"><i className="iconsminds-add-user"></i> Cadastrar Vendedor</Dropdown.Item>
                <Dropdown.Item as={Link} to="/vendedor/fila"><i className="iconsminds-target-market"></i> Vendedor da Vez</Dropdown.Item>
                </DropdownButton>
              </li>


              
              <li>
                <DropdownButton
                  id="dropdown-clientes"
                  title={
                    <>
                      <i className="iconsminds-target-market"></i>
                      <span>Recursos</span>
                    </>
                  }
                  drop="down"
                  variant="link"
                  className="menu-item  dropdown-clientes"
                >
                <Dropdown.Item as={Link} to="/recursos/cashback"><i className="iconsminds-money-bag"></i> Cashback</Dropdown.Item>
                <Dropdown.Item as={Link} to="/recursos/indicacoes"><i className="iconsminds-male-female"></i> Indicações</Dropdown.Item>
                <Dropdown.Item as={Link} to="/recursos/registro_de_pontos"><i className="iconsminds-target-market"></i> Ponto Digital</Dropdown.Item>
                <Dropdown.Item as={Link} to="/recursos/configuracao_mensagem"><i className="iconsminds-email"></i> Configuração de Mensagem</Dropdown.Item>
                <Dropdown.Item as={Link} to="/recursos/whatsapp"><FontAwesomeIcon icon={faWhatsapp} className="FontAwesomeIcon" /> Whatsapp</Dropdown.Item>
                </DropdownButton>
              </li>

              
              
              <li>
                <DropdownButton
                  id="dropdown-clientes"
                  title={
                    <>
                      <i className="iconsminds-gear-2"></i>
                      <span>Configuração</span>
                    </>
                  }
                  drop="down"
                  variant="link"
                  className="menu-item  dropdown-clientes"
                >
                <Dropdown.Item as={Link} to="/configuracao/conta"><i className="iconsminds-user"></i> Conta</Dropdown.Item>
                <Dropdown.Item as={Link} to="/configuracao/usuario"><i className="iconsminds-conference"></i> Usuários</Dropdown.Item>
                </DropdownButton>
              </li>



              <li>
                <Link as={Link} to="https://clienteprime.com.br/pontoDigital" className="menu-item">
                  <i className="iconsminds-check"></i>
                  <span>Ponto Digital</span>
                </Link>
              </li>

            

            </ul>
         
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default MenuOffcanvas;
