import {  faSave} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import axios from 'axios'
import swal from 'sweetalert';
import "./cashback.css";


function PontuarColuna2() {

  const [cashback, setCashback] = useState({
    ativar: false,
    percentual: '',
    centavos: false,
    prazo_expiracao_ativar: false,
    minimo: '',
    limite: '',
    prazo_expiracao: '',
  })

  

  useEffect(() => {
    const fetchData = async () => {
      const token = sessionStorage.getItem('token');
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        };
        const response = await axios.get(
          `${process.env.REACT_APP_URLBK}/v-1/cashback/configuracao`,
          { headers }
        );

        if (response.status === 200) {
          const data = response.data;
          console.log(data)
          setCashback({
            ...data,
            ativar: !!data.ativar, // Converte para booleano
            centavos: !!data.centavos, // Converte para booleano
            prazo_expiracao_ativar: !!data.prazo_expiracao_ativar, // Converte para booleano
          });
        }
      } catch (error) {
        alert(error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
    
    setCashback((prevCashback) => ({
      ...prevCashback,
      [name]: inputValue,
    }));
};

function mascaraMoeda(event) {
  const onlyDigits = event.target.value
      .split("")
      .filter(s => /\d/.test(s))
      .join("")
      .padStart(3, "0")
  const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)
  event.target.value = maskCurrency(digitsFloat)
}

 function maskCurrency(valor, locale = 'pt-BR', currency = 'BRL') {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency
        }).format(valor)
    }


  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem('token');
    try {
      const data = {
        ...cashback
      }
        
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_URLBK}/v-1/cashback/configuracao`,
        data,
        { headers }
      );
      if (response.status === 200) {
        swal('Pronto!', response.data.message, "success");
      }
    } catch (error) {
      if (error.response) {
        console.error("Erro do servidor:", error.response.data);
    
        // Extrai a mensagem de erro corretamente
        let errorMessage = error.response.data.error;
    
        if (typeof errorMessage === "string") {
          errorMessage = errorMessage.replace(/[\[\]']/g, ""); // Remove colchetes e aspas extras
        }
    
        
        swal(errorMessage || "Ocorreu um erro inesperado.", '', "warning");
      } else if (error.request) {
        console.error("Sem resposta do servidor:", error.request);
        alert("Erro: Sem resposta do servidor.");
      } else {
        console.error("Erro na configuração da requisição:", error.message);
        alert("Erro: " + error.message);
      }
    }
    
    
    
  };

  return (
    <Form onSubmit={handleFormSubmit}>
   
      <div className="d-flex gap-3 mb-3">
    <Form.Check
      type="switch"
      name="ativar"
      id="custom-switch-1"
      label="Ativar Cashback"
      checked={cashback.ativar}
      onChange={handleInputChange}
      className="custom-switch-lg custom-switch-color"
    />

    <Form.Check
      type="switch"
      name="prazo_expiracao_ativar"
      id="custom-switch-2"
      label="Prazo de Expiração"
      checked={cashback.prazo_expiracao_ativar}
      onChange={handleInputChange}
      className="custom-switch-lg custom-switch-color"
    />


        <Form.Check // prettier-ignore
        type="switch"
        name='centavos'
        id="custom-switch"
        label="Permitir Centavos. ex: R$ 6,55"
        checked={cashback.centavos}
        onChange={handleInputChange}
  className="custom-switch-lg custom-switch-color"
      />
   
  </div>


      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
        <Form.Label className='bold'>Percentual de Cashback</Form.Label>
        <Form.Control type="number"  className="form-control input" placeholder="0%" name='percentual' onChange={handleInputChange} value={cashback['percentual']}/>
      </Form.Group>
      
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
        <Form.Label className='bold'>Valor Minimo para Regate</Form.Label>
        <Form.Control type="text" className="form-control input" placeholder="R$ 0" name='minimo' onChange={handleInputChange} onInput={(event) => mascaraMoeda(event)} value={cashback['minimo']}/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
        <Form.Label className='bold'>Limitar o valor do Resgate (Valor em porcentagem)</Form.Label>
        <Form.Control type="number" className="form-control input" placeholder="%" name='limite' onChange={handleInputChange} value={cashback['limite']}/>
      </Form.Group>
     
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
        <Form.Label className='bold'>Prazo de Expiração em dias</Form.Label>
        <Form.Control type="number" className="form-control input" placeholder="0" name='prazo_expiracao' min="20" onChange={handleInputChange} disabled={!cashback.prazo_expiracao_ativar} value={cashback['prazo_expiracao']}/>
      </Form.Group>
      <div className='mb-3'>
        <Button className='m-1 usuarionovo-button-criar' type='submit'><FontAwesomeIcon icon={faSave} size='2x' /> Salvar</Button>
      </div>
    </Form>

  )
}

export default PontuarColuna2