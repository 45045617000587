import React from 'react';
import './ProfileCard.css';
import FotoPerfil from '../../assets/foto-perfil.webp';

const ProfileCard = ({ cliente }) => {
    console.log(cliente);

    // Função para verificar se o cliente foi cadastrado nos últimos 30 dias
    const isNewClient = () => {
        if (!cliente?.dataCadastro) return false;

        const dataCadastro = new Date(cliente.dataCadastro);
        const dataAtual = new Date();

        // Calcula a diferença em milissegundos e converte para dias
        const diffTime = Math.abs(dataAtual - dataCadastro);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return diffDays <= 30; // Verifica se está dentro de 30 dias
    };

    return (
        <div className="card-container">
            {isNewClient() && <span className="pro">NOVO</span>}
            <img src={FotoPerfil} alt="user" />
            <h3>{cliente?.nome || 'Nome não disponível'}</h3>
            <h6>{cliente?.cpf || 'CPF não disponível'}</h6>
            <p>{cliente?.email || 'Email não disponível'}</p>
            <p>{cliente?.telefone || 'Telefone não disponível'}</p>
            <p>Data de Nascimento: {cliente?.dataNascimento || 'Data não disponível'}</p>
        </div>
    );
};

export default ProfileCard;