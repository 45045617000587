import React from 'react'
import { Col, Container, Row, Modal, Card } from 'react-bootstrap'
import GenericBoxView from '../../components/ViewGeneric/GenericBoxView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckToSlot } from '@fortawesome/free-solid-svg-icons'
import DetalheColuna1 from '../../components/DetalheCliente/DetalheColuna1'
import DetalheColuna2 from '../../components/DetalheCliente/DetalheColuna2'
import { useLocation } from 'react-router-dom';
import ProfileCard from '../../components/Cards/ProfileCard'
import HistoricoCliente from '../../components/Clientes/HistoricoCliente'
import DashboardCardsPcliente from '../../components/dashboard/DashboardCardsPcliente'

function DetalheCliente() {

    const location = useLocation();
    const cliente = location.state;
  
    console.log(cliente.nome);


  return (
    <>
    <Row className='dashboard-graficos'>
        
          <Col xs={4} >
          <ProfileCard 
          cliente={cliente}
          />
          </Col>
          <Col xs={8} >
          <section className="description">
         <br></br>
        <DashboardCardsPcliente 
        icon="iconsminds-male"
        title1="Pontos"
        title2="Cashback"
        currentValue1={(Math.trunc(cliente.pontos * 100) / 100).toFixed(2)}
        currentValue2={(Math.trunc(cliente.cashback * 100) / 100).toFixed(2)}
    />
      
        </section>
          <HistoricoCliente 
          cliente={cliente}
          />
          </Col>
      </Row>
      </>
  )
}

export default DetalheCliente