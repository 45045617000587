import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Modal, Card } from 'react-bootstrap'
import './Dashboard.css'
import DashboardButtons from '../../components/dashboard/DashboardButtons'
import DadosPlataforma from '../../components/dashboard/DadosPlataforma'
import DashboardCards from '../../components/dashboard/DashboardCards'
import DashboardCardsMobile from '../../components/dashboard/DashboardCardsMobile'
import ProgressCard from '../../components/dashboard/ProgressCard'
import { GraficoPlataforma, GraficoPlataforma2 } from '../../components/dashboard/GraficoPlataforma'
import CadastroClienteModel from '../../components/modelsGenericos/CadastroCliente'
import PontosDistribuidos from '../../views/Cliente/PontosDistribuidos';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {  Link } from 'react-router-dom';


dayjs.extend(customParseFormat);

function calculateAge(birthday) {
  const birthDate = new Date(birthday);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  
  return age;
}


function Dashboard() {
    const [showCadastro, setShowCadastro] = React.useState(false);
    const [aniversariantes, setAniversariantes] = useState([]);
    const [totalAniversariantesHoje, setAniversariantesQtd] = useState(0);
    const [totalClientes, setTotalClientes] = useState(0);
    const [totalVendas, setTotalVendas] = useState(0);
    const [totalVendasHoje, setTotalVendasHoje] = useState(0);


    var showCadastroModal = () => {
        setShowCadastro(true);
    }

    const handleClose = () => {
        setShowCadastro(false);
    };

      useEffect(() => {
        fetchAniversariantes();
      }, []);




const fetchAniversariantes = async () => {
  try {
    const token = sessionStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token}`,
    };

    const response = await axios.get(
      `${process.env.REACT_APP_URLBK}/v-1/clientes/consulta/aniversariante/`,
      { headers }
    );

    console.log('Dados recebidos:', response.data); // 🔍 Verificar formato da data

    
    const totais = await axios.get(
      `${process.env.REACT_APP_URLBK}/v-1/clientes/consulta/totais-por-empresa/`,
      { headers }
    );
    

    // Atualize o estado com total_clientes
    setTotalClientes(totais.data.total_clientes);
    setTotalVendas(totais.data.total_vendas);
    setTotalVendasHoje(totais.data.total_vendas_hoje);

    const today = dayjs().format('MM-DD'); 
    //const today = '02-22';

    const aniversariantesHoje = response.data.filter(aniversariante => {
      const nascimento = dayjs(aniversariante.dataNascimento, ['YYYY-MM-DD', 'DD/MM/YYYY']).format('MM-DD');
      return nascimento === today;
    });

    const aniversariantesWithAge = aniversariantesHoje.map((aniversariante) => ({
      ...aniversariante,
      idade: calculateAge(aniversariante.dataNascimento),
    }));

    setAniversariantesQtd(aniversariantesWithAge.length);
    setAniversariantes(aniversariantesWithAge);
  } catch (error) {
    console.error('Erro ao buscar aniversariantes:', error);
  }
};


    return (
        <Container fluid className='container-dashboard '>
            <div className='dashboard-div-title'>
                <h3>Dashboard</h3>
            </div>

            
          <br></br><br></br>
            
            <Row className='dashboard-graficos'>
            <DashboardCardsMobile />
                <Col xs={7} className='sumir-mobile'>
                    
                    <Card className='dashboard-card-model DashboardCards-principal'>
                    <DashboardCards clientes={totalClientes} vendas={totalVendas} vendas_hoje={totalVendasHoje} />
                    </Card>
                </Col>
                <Col xs={5} className='sumir-mobile'>
                <Card className='dashboard-card-model DashboardCards-principal '>
                    
        <br></br>

               <Link to="/aniversariantesClientesHoje" style={{ textDecoration: 'none' }}>
  <ProgressCard 
      icon="iconsminds-cake"
      title={totalAniversariantesHoje}
      subtitle="Aniversariantes hoje"
      currentValue={0}
      maxValue={0}
  />
</Link>

                    </Card>
                    <br></br>
               
                </Col>

                <Col xs={12}>
                
                <PontosDistribuidos />

                   
               
                </Col>
            </Row>

            {/*<TabelasPlataforma /> */}
            <Modal show={showCadastro} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Cadastro Cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CadastroClienteModel handleClose={handleClose} />
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default Dashboard