import React, { useState, useEffect } from 'react';
import { Table, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import NavbarComp from '../../components/Estrutura/NavbarCaixa';
import swal from 'sweetalert';
import axios from 'axios';
import './base.css';

const PlataformaVendedor = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [usuariosCaixa, setUsuariosCaixa] = useState([]);

    useEffect(() => {
        async function fetchVendedores() {
            try {
                const token = sessionStorage.getItem('token');
                const response = await axios.get(process.env.REACT_APP_URLBK + '/v-1/vendedores/consulta_vendedor', {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Token " + token
                    },
                    credentials: "include",
                });
                setUsuarios(response.data.usuarios);
            } catch (error) {
                console.log(error);
            }
        }
        fetchVendedores();
    }, []);

    const moverParaCaixa = (usuario) => {
        setUsuarios(usuarios.filter((u) => u.id !== usuario.id));
        setUsuariosCaixa([...usuariosCaixa, usuario]);
    };

    const removerDoCaixa = (usuario) => {
        swal({
            title: "Selecione uma opção",
            text: "O usuário realizou uma venda?",
            content: (() => {
                const div = document.createElement("div");
                div.innerHTML = `
                    <select id="selectVenda" class="form-select">
                        <option value="" disabled selected>Escolha uma opção</option>
                        <option value="sim">Vendeu</option>
                        <option value="nao">Não Vendeu</option>
                    </select>
                `;
                return div;
            })(),
            buttons: {
                cancel: "Cancelar",
                confirm: {
                    text: "Confirmar",
                    closeModal: false
                }
            }
        }).then((value) => {
            if (value) {
                const selectElement = document.getElementById("selectVenda");
                const opcaoSelecionada = selectElement ? selectElement.value : "";

                if (!opcaoSelecionada) {
                    swal("Erro", "Por favor, selecione uma opção!", "error");
                    return;
                }

                if (opcaoSelecionada == 'nao') {
                    

                    swal({
                        title: "Selecione uma opção",
                        text: "Porque a venda não foi realizada?",
                        content: (() => {
                            const div = document.createElement("div");
                            div.innerHTML = `
                                <select id="selectVenda" class="form-select">
                                    <option value="" disabled selected>Escolha uma opção</option>
                                    <option value="Falta de estoque">Falta de estoque</option>
                                    <option value="Cliente Desistiu">Cliente Desistiu</option>
                                </select>
                            `;
                            return div;
                        })(),
                        buttons: {
                            cancel: "Cancelar",
                            confirm: {
                                text: "Confirmar",
                                closeModal: false
                            }
                        }
                    }).then((value) => {

                        const selectElement = document.getElementById("selectVenda");
                        const opcaoSelecionada = selectElement ? selectElement.value : "";
                        if (!opcaoSelecionada) {
                            swal("Erro", "Por favor, selecione uma opção!", "error");
                            return;
                        }


                        // Remove o usuário do caixa e o adiciona ao final da fila NFC
                setUsuariosCaixa(usuariosCaixa.filter((u) => u.id !== usuario.id));
                setUsuarios([...usuarios, usuario]);

                swal("Sucesso", `Usuário marcado como: ${opcaoSelecionada === "sim" ? "Vendeu" : "Não Vendeu"}`, "success");


                    });
                    



                }else{
                    // Remove o usuário do caixa e o adiciona ao final da fila NFC
                setUsuariosCaixa(usuariosCaixa.filter((u) => u.id !== usuario.id));
                setUsuarios([...usuarios, usuario]);

                swal("Sucesso", `Usuário marcado como: ${opcaoSelecionada === "sim" ? "Vendeu" : "Não Vendeu"}`, "success");
                }

                
            }
        });
    };

    return (
        <div className="page-container">
            <NavbarComp />
            <div className="content">
                <div className="nfc-section">
                <br></br>
                <br></br>
                <br></br>
     <br></br>
     <br></br>
     <br></br>
                    <h3>Vendedores na fila</h3>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Posição</th>
                                <th>Nome</th>
                                <th>Email</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usuarios.map((usuario, index) => (
                                <tr key={usuario.id}>
                                    <td>{index + 1}</td>
                                    <td>{usuario.login}</td>
                                    <td>{usuario.email}</td>
                                    <td>
                                        <Button variant="primary" onClick={() => moverParaCaixa(usuario)}>
                                            <FontAwesomeIcon icon={faArrowRight} />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="caixa">
                <br></br>
                <br></br>
                <br></br>
     <br></br>
                    <h3>Vendedores em Atendimento</h3>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Email</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usuariosCaixa.map((usuario) => (
                                <tr key={usuario.id}>
                                    <td>{usuario.login}</td>
                                    <td>{usuario.email}</td>
                                    <td>
                                        <Button variant="danger" onClick={() => removerDoCaixa(usuario)}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default PlataformaVendedor;
