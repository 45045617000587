import React, { useState, useEffect } from 'react';
import GenericBoxView from '../../components/ViewGeneric/GenericBoxView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGifts } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, Table } from 'react-bootstrap';
import AniversariantesColuna1 from '../../components/Aniversariantes/AniversariantesColuna1';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);


function calculateAge(birthday) {
  const birthDate = new Date(birthday);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  
  return age;
}

function AniversariantesHoje() {
  const [aniversariantes, setAniversariantes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = process.env.REACT_APP_ITEM_FOR_PAGE;

  useEffect(() => {
    fetchAniversariantes();
  }, []);

 
const fetchAniversariantes = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      };
  
      const response = await axios.get(
        `${process.env.REACT_APP_URLBK}/v-1/clientes/consulta/aniversariante/`,
        { headers }
      );
  
      console.log('Dados recebidos:', response.data); // 🔍 Verificar formato da data
  
      const today = dayjs().format('MM-DD'); 
      //const today = '02-22';
  
      const aniversariantesHoje = response.data.filter(aniversariante => {
        const nascimento = dayjs(aniversariante.dataNascimento, ['YYYY-MM-DD', 'DD/MM/YYYY']).format('MM-DD');
        return nascimento === today;
      });
  
      const aniversariantesWithAge = aniversariantesHoje.map((aniversariante) => ({
        ...aniversariante,
        idade: calculateAge(aniversariante.dataNascimento),
      }));
  
      setAniversariantes(aniversariantesWithAge);
    } catch (error) {
      console.error('Erro ao buscar aniversariantes:', error);
    }
  };
  const totalPages = Math.ceil(aniversariantes.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = aniversariantes.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

  return (
    <>
      <GenericBoxView
        icon={<FontAwesomeIcon icon={faGifts} size='2x' />}
        title={'Aniversariantes de Hoje'}
        titleBox={'Aniversariantes de Hoje'}
        coluna1={ <>
              <div className='icon-text'>
                <FontAwesomeIcon icon={faGifts} size='2x' />
                <h3>  Aniversariantes</h3>
              </div>
              <p>Este relatório apresenta os aniversariantes do dia corrente.</p>
            </>}
      />
      <hr></hr>
      <Card>
        <Card.Title className='cadastroNovoscliente-card-title'><h4> Resultados</h4></Card.Title>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Cliente</th>
                <th>E-mail</th>
                <th>Data de Nascimento</th>
                <th>Idade</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((aniversariante) => (
                <tr key={aniversariante.cliente_id}>
                  <td>{aniversariante.nome}</td>
                  <td>{aniversariante.email}</td>
                  <td>{aniversariante.dataNascimento}</td>
                  <td>{aniversariante.idade} Anos</td>
                  <td>
                    {/* Coloque aqui os botões de ação para cada aniversariante */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
           
           
           {/* Paginação */}
<div className="d-flex justify-content-center">
  <ul className="pagination">
    {/* Botão Anterior */}
    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
      <Button
        className="page-link"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Anterior
      </Button>
    </li>

    {/* Lógica para limitar a exibição a 6 páginas */}
    {Array.from({ length: Math.min(6, totalPages) }).map((_, index) => {
      // Calcula a página inicial e final visíveis
      const startPage = Math.max(1, currentPage - 3);
      const endPage = Math.min(totalPages, startPage + 5);

      const page = startPage + index;
      if (page > totalPages) return null;

      return (
        <li
          key={page}
          className={`page-item ${page === currentPage ? 'active' : ''}`}
        >
          <Button className="page-link" onClick={() => handlePageChange(page)}>
            {page}
          </Button>
        </li>
      );
    })}

    {/* Botão Próxima */}
    <li
      className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
    >
      <Button
        className="page-link"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Próxima
      </Button>
    </li>
  </ul>
</div>

           


        </Card.Body>
      </Card>
    </>
  );
}

export default AniversariantesHoje;