import React from 'react'
import { DropdownButton, Dropdown, Col } from 'react-bootstrap'
import Avatar from 'react-avatar';

function logout() {
    localStorage.clear()
    sessionStorage.clear()
};

export default function avatar() {
    var username = sessionStorage.getItem('username')
    var role = sessionStorage.getItem('role')
    return (
        <Col className='coluna-avatar'>
            <div className='nome-role'>
                <div>{username}</div>
                <div>{role && role === '1' ? `Administrador` : `Funcionario`}</div>
            </div>
            <div>
                <DropdownButton className='custom-dropdown '
                    key='down'
                    id={`dropdown-button-drop-down`}
                    drop='down'
                    variant="transparent"
                    title={<Avatar name={username} round={true} size='50' />}

                >

                    <Dropdown.Item className='menu-avatar' href={`/configuracao/usuario/editar/${sessionStorage.getItem('id')}`}>Trocar de senha</Dropdown.Item>
                    <Dropdown.Item className='menu-avatar' onClick={logout} href="/">Logout</Dropdown.Item>
                </DropdownButton>
            </div>
        </Col>
    )
}
