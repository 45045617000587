import React from 'react';
import './DashboardCards.css';
import "../Estrutura/iconsmind-s/css/iconsminds.css";
import { Col, Container, Row, Modal, Card } from 'react-bootstrap'

const DashboardCard = ({ icon, title, count }) => {
    return (
        <div className="card">
            <div className="card-body">
                <i className={icon}></i>
                <p className="card-text mb-0">{title}</p>
                <p className="lead">{count}</p>
            </div>
        </div>
    );
};

const DashboardCardsMobile = () => {
    const cardsData = [
        { icon: 'iconsminds-conference', title: 'Clientes', count: 3 },
        { icon: 'iconsminds-basket-coins', title: 'Vendas', count: 0 },
        { icon: 'iconsminds-arrow-refresh', title: 'Médias', count: 0 },
        { icon: 'iconsminds-heart', title: 'Satisfação', count: 10 },
    ];

    return (
        <>
            {cardsData.map((card, index) => (
                <Col xs={6} className='sumir-desktop'><DashboardCard key={index} {...card} />
                <br></br>
                </Col>
            ))}
        </>
    );
};

export default DashboardCardsMobile;
