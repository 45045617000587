// ProgressCard.js
import React from 'react';
import './ProgressCard.css';

const ProgressCard = ({ icon, title, subtitle, currentValue, maxValue }) => {
    const strokeDasharray = 301.635;
    const strokeDashoffset = strokeDasharray - (currentValue / maxValue) * strokeDasharray;

    return (
        <div className="card mb-4 progress-card">
            <div className="progress-card-body justify-content-between d-flex flex-row align-items-center">
                <div>
                    <br></br>
                    <div>
                        <p className="progress-card-lead">{title}</p>
                        <p className="progress-card-text-small">{subtitle}</p>
                    </div>
                </div>
                <div>
                <i className={`${icon} progress-card-icon align-text-bottom d-inline-block`}></i>
                </div>
            </div>
        </div>
    );
};

export default ProgressCard;
