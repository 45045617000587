import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Button, Card, Form, Image, Modal, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faMoneyBill, faMoneyBillTransfer, faTicket, faUserPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import { faChartBar } from '@fortawesome/free-regular-svg-icons'
import InputMask from 'react-input-mask';
import logo from '../../assets/logo.png'
import carrinho from '../../assets/buttons/carrinho.png'
import axios from 'axios'
import CadastroCliente from '../../components/modelsGenericos/CadastroCliente'
import EditarCliente from '../../components/modelsGenericos/EditarCliente'
import VoucherCliente from '../../components/modelsGenericos/VoucherCliente'
import fetchCashback from '../../services/configuracao/cashback'
import fetchClientes from '../../services/clientes/previsualizacao'
import { format } from 'date-fns';
import adicionar from '../../services/cashback/adicionar'
import resgatar from '../../services/cashback/resgatar'
import compraAddResgatar from '../../services/cashback/add-resgatar'
import Porco from '../../assets/buttons/porco.png'
import FotoPerfil from '../../assets/foto-perfil.webp'
import Resgatar from '../../assets/buttons/resgatar.png'
import Voucher from '../../assets/buttons/voucher.png'
import Cadastrar from '../../assets/buttons/cadastrar.png'
import Historico from '../../assets/buttons/historico.png'
import Sair from '../../assets/buttons/sair.png'
import "./App.css";
import Avatar from '../../components/Avatar/Avatar'
import NavbarComp from '../../components/Estrutura/NavbarCaixa';
import swal from 'sweetalert';
import { faTrash, faGift, faStar, shoppingBag } from '@fortawesome/free-solid-svg-icons';
import { CalculatorIcon, ShoppingBagIcon } from '@heroicons/react/24/outline'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import "../../components/Estrutura/iconsmind-s/css/iconsminds.css";
import aniversario from '../../assets/aniversario.jpg'
import CardModalCaixaCompra, { CardModalCaixaCompraUnico } from '../../components/dashboard/CardModalCaixaCompra'
import RegraTipoFatura from '../../components/MetodosGerais/RegraTipoFatura'

// import CurrencyInputField from 'react-currency-input-field';

const Caixa = () => {

    const initialClienteData = {
        cpf: '',
        valor: '',
        codigo_venda: '',
        numeroVenda: '',
        valorNumber: 0,
        tipoFaturaSelecionado: '',
    };
    const [cashbackConfig, setCashbackConfig] = useState({
        ativar: false,
        percentual: '',
        centavos: false,
        minimo: '',
        limite: '',
    })
    const [pontosAtuaisCliente, setPontosAtuaisCliente] = useState({
        pontos: 0,
        cashback: 0,
    });

    const [clienteData, setClienteData] = useState(initialClienteData);
    const [showCadastro, setShowCadastro] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [showVoucher, setShowVoucher] = useState(false);
    const [showHistorico, setShowHistorico] = useState(false);
    const [preVisualizacao, setPreVisualizacaoCaixa] = useState();
    const [showNotificationCompra, setShowNotificationCompra] = useState(false);
    const [showNotificationCompraSemPonto, setShowNotificationCompraSemPonto] = useState(false);
    const [historicos, setHistoricos] = useState([]);
    const [tipoFaturaSelecionado, setTipoFaturaSelecionado] = useState(''); // Novo estado
    const [numeroVenda, setNumeroVenda] = useState(''); // Novo estado
    const [dadosCliente, setDadosCliente] = useState(0);


    const calcularCashbackUtilizavel = () => {
        if (!dadosCliente || !Array.isArray(dadosCliente) || dadosCliente.length === 0) {
            return 0; // Retorna 0 se não houver dados do cliente
        }
    
        const valorCompra = clienteData.valorNumber || 0;
        const cashbackDisponivel = dadosCliente?.[0]?.pontos || 0;
        const limitePercentual = parseFloat(cashbackConfig.limite) || 0;
    
        if (!valorCompra || !limitePercentual) return 0;
    
        const maxCashbackUtilizavel = valorCompra * (limitePercentual / 100);
        return Math.min(cashbackDisponivel, maxCashbackUtilizavel);
    };
    
    const calcularCashbackPontos = () => {
        const valorCompra = clienteData.valorNumber || 0;
        const limitePercentual = parseFloat(cashbackConfig.limite) || 0;
        
        if (!valorCompra || !limitePercentual || !dadosCliente?.[0]?.pontos) return 0;
    
        // Calcula o cashback com base nos pontos
        let cashback = (dadosCliente[0].pontos * cashbackConfig.percentual) / 100;
    
        // Calcula o limite de cashback permitido baseado no valor da compra
        const limiteCashback = (valorCompra * limitePercentual) / 100;
    
        // Se o cashback for menor que metade do limite, usa todo o cashback
        if (cashback < limiteCashback / 2) {
            return cashback;
        }
    
        // Caso contrário, aplica o limite máximo permitido
        return Math.min(cashback, limiteCashback);
    };

    const calcularPontosUtilizados = (pontos) => {
    
        // Calcula o cashback com base nos pontos
        let total = (pontos * cashbackConfig.percentual);
    
  
        // Caso contrário, aplica o limite máximo permitido
        return total;
    };
    
    
    
    
    
    

    const buscarDadosCliente = async (cpf) => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            };
    
            const response = await axios.get(`${process.env.REACT_APP_URLBK}/v-1/clientes/consulta?documento=${cpf}`, { headers });
    
            console.log("Dados do cliente recebidos:", response.data); // Verifique a resposta
    
            if (!response.data || !Array.isArray(response.data) || response.data.length === 0) {
                setDadosCliente([]); // Garante que `dadosCliente` nunca seja `null`
            } else {
                setDadosCliente(response.data);
            }
    
            return response.data;
        } catch (error) {
            console.error("Erro ao buscar dados do cliente:", error);
            setDadosCliente([]); // Evita `null`
            return [];
        }
    };
    
    
    
      


  const handleAlertaNumeroVenda = () => {
    swal({
      title: "Preencha todos os campos!",
      text: "Você só pode pontuar com todos os campos preenchidos!",
      icon: "warning",
      button: "Entendido!",
      dangerMode: true,
    });
  };


  const handleAlertaSelecionar = () => {
   

      swal({
        content: {
          element: "div",
          attributes: {
            innerHTML: `
              <div style="display: flex; flex-direction: column; align-items: center;">
                <i class="iconsminds-diamond" style="font-size: 80px !important; color: #2A3072; margin-bottom: 10px;"></i>
                <h2 style="margin: 0; font-size: 25px; color: #2A3072"><b>O que deseja fazer <br>com esta compra?</b></h2>
              </div>
            `,
          }
        },
        buttons: {
          cancel: {
            text: "PONTUAR CLIENTE",
            visible: true,
            className: "custom-cancel-button"
          },
          confirm: {
            text: "RESGATAR OS PONTOS",
            className: "custom-confirm-button"
          }
        },
        dangerMode: true,
      })
      .then((willUsePoints) => {
        if (willUsePoints) {
            setShowNotificationCompra(true)
        } else {
            setShowNotificationCompraSemPonto(true)
        }
      });
   
  };



    useEffect(() => {
        async function fetchData() {
            try {
                const cashbackConfigAtual = await fetchCashback();
                setCashbackConfig(() => cashbackConfigAtual);
            } catch (error) {
                console.error('Erro ao buscar configuração de cashback:', error);
            }
        }

        fetchData(); // Chama a função assíncrona imediatamente

    }, []);

    useEffect(() => {
        // Remove caracteres não numéricos do CPF
        const numericCPF = clienteData.cpf.replace(/\D/g, '');

        // Verifica se o CPF tem 11 dígitos
        if (numericCPF.length === 11) {
            fetchClientes(
                clienteData.cpf,
                cashbackConfig,
                setPontosAtuaisCliente,
                setPreVisualizacaoCaixa,
                showCadastroModal,
                showEditarModal
            );
            buscarDadosCliente(clienteData.cpf);
        }
        else {
            setPreVisualizacaoCaixa('')
            setPontosAtuaisCliente({
                pontos: 0,
                cashback: 0,
            })
        }
    }, [clienteData.cpf]);

    const handleInputChange = (e) => {
        var { name, value } = e.target;
        if (name === "valor") {
            var valorString = String(value).replace("R$", "").trim();
            var valorNumber = parseFloat(valorString.replace(/\./g, "").replace(",", "."));
            setClienteData(prevData => ({
                ...prevData,
                valorNumber: valorNumber,
            }));
        }
        setClienteData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

    };


    const handleTipoFaturaChange = (e) => {
        setTipoFaturaSelecionado(e.target.value); // Atualiza estado de Tipo de Fatura
    };

    const handleNumeroVendaChange = (e) => {
        setNumeroVenda(e.target.value); // Atualiza estado de Número de Venda
    };

    var showCadastroModal = () => {
        setShowCadastro(true);
    }

    var showEditarModal = () => {
        setShowEditar(true);
    }
    var showVoucherModal = () => {
        setShowVoucher(true);
    }

    const fetchHistoricos = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            };
            var cpfTratado = clienteData.cpf

            var dados = {
                "cpf": cpfTratado,
            }
            const response = await axios.post(`${process.env.REACT_APP_URLBK}/v-1/historico/movimentacao`, dados, { headers }); 
            setHistoricos(response.data);
        } catch (error) {
            console.error('Erro ao buscar dados do histórico:', error);
        }
    };

    var showHistoricoModal = () => {
        if(clienteData.cpf){
            fetchHistoricos();
            setShowHistorico(true);
        }else{
            alert("Digite um cpf valido")
        }
       
    }

    const handleClose = () => {
        setShowNotificationCompra(false);
        setShowNotificationCompraSemPonto(false);
        setShowCadastro(false);
        setShowEditar(false);
        setShowVoucher(false);
        setShowHistorico(false);
    };

    const handleVoltar = () => {
        if (sessionStorage.getItem('role') === "1") {
            window.location.href = '/'
        } else {
            localStorage.clear()
            sessionStorage.clear()
            window.location.href = '/'
        }
    };

    function mascaraMoeda(event) {
        const onlyDigits = event.target.value
            .split("")
            .filter(s => /\d/.test(s))
            .join("")
            .padStart(3, "0")
        const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)
        event.target.value = maskCurrency(digitsFloat)
    }

    function maskCurrency(valor, locale = 'pt-BR', currency = 'BRL') {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency
        }).format(valor)
    }

    const handleAdd = async () => {

        const updatedClienteData = {
            ...clienteData,
            numeroVenda: numeroVenda,
            tipoFaturaSelecionado: tipoFaturaSelecionado,
        };
    
        
        try{
            
        var response = await adicionar(updatedClienteData);

            //alert('Pontos resgatados com sucesso:', response.data);
            swal({
                title: "Pontos adicionados com sucesso!",
                icon: "success",
                buttons: {
                  confirm: {
                    text: "Entendido",
                    className: "custom-confirm-button"
                  }
                },
                dangerMode: true,
              })
            
            setClienteData(initialClienteData);
            
        }catch (error) {
            // Tratamento de erro
            if (error.response && error.response.data && error.response.data.error) {
                swal({
                    title: error.response.data.error,
                    icon: "error",
                });
            } else {
                swal({
                    title: "Erro inesperado",
                    text: "Ocorreu um erro ao processar sua solicitação. Tente novamente mais tarde.",
                    icon: "error",
                });
            }
        }
        handleClose();
    };

    const handleResgatePontos = async (event) => {
        event.preventDefault();
        var response = await resgatar(clienteData)
        try{
            swal({
                title: "Pontos resgatados com sucesso!",
                icon: "success",
                buttons: {
                  confirm: {
                    text: "Entendido",
                    className: "custom-confirm-button"
                  }
                },
                dangerMode: true,
              })
            setClienteData(initialClienteData);
           
        }catch(error){
            //alert('Pontos resgatados com sucesso:', response.data);
            alert('Erro ao resgatar pontos o cliente: ' + response.response.data['error']);
        }
    };

    const handleCashbackReducao = async () => {
        const updatedClienteData = {
            ...clienteData,
            numeroVenda: numeroVenda,
            tipoFaturaSelecionado: tipoFaturaSelecionado,
        };
    
        swal({
            title: "Você selecionou utilizar Cashback",
            text: "Deseja Prosseguir com essa ação?",
            icon: "warning",
            buttons: {
                cancel: {
                    text: "CANCELAR",
                    visible: true,
                    className: "custom-cancel-button"
                },
                confirm: {
                    text: "CONFIRMAR",
                    className: "custom-confirm-button-cancelar"
                }
            },
            dangerMode: true,
        })
        .then(async (willDelete) => { // Adiciona async aqui
            if (willDelete) {
                try {
                    const response = await compraAddResgatar(updatedClienteData);
                    console.log(response);
                
                    swal('Pontos resgatados com sucesso!', response.data.message || "Operação concluída", {
                        icon: "success",
                    });
                
                    setClienteData(initialClienteData);
                } catch (error) {
                    console.error("Erro ao resgatar pontos:", error); // Log mais detalhado
                
                    let errorMessage = "Erro desconhecido";
                
                    if (error.response) {
                        errorMessage = error.response.data?.error || "Erro no servidor";
                    } else if (error.request) {
                        errorMessage = "Sem resposta do servidor";
                    } else {
                        errorMessage = error.message || "Erro inesperado";
                    }
                
                    swal({
                        title: "Erro ao resgatar pontos do cliente",
                        text: errorMessage,
                        icon: "error",
                    });
                }
                
                handleClose();
            }
        });
    };
    

    const handleLimparCaixa = () => {
        setClienteData(initialClienteData); // Redefine os valores do cliente para o estado inicial
        setPontosAtuaisCliente({
            pontos: 0,
            cashback: 0,
        }); // Redefine os pontos e cashback
        setPreVisualizacaoCaixa(''); // Limpa a pré-visualização
        setNumeroVenda(''); // Limpa o número de venda
        setTipoFaturaSelecionado(''); // Limpa o tipo de fatura selecionado
    };

    const handleCPFChange = async (event) => {
        const cpf = event.target.value;

        if (cpf.length === 14) {
            
            try {
                const token = sessionStorage.getItem('token');
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                };

                const response = await axios.get(
                    `${process.env.REACT_APP_URLBK}/v-1/clientes/verificacao_pontos`,
                    { headers }
                );

                const response_niver = await axios.get(
                    `${process.env.REACT_APP_URLBK}/v-1/clientes/verificacao_pontos`,
                    { headers }
                );

                // Aqui você pode tratar a resposta do servidor
                const clienteInfo = response.data;

                //tratar responsta da api niver
                const niver = response_niver.data;
                console.log(niver);

                
                // Atualizar os dados do clienteData com as informações recebidas
                setClienteData((prevData) => ({
                    ...prevData,
                    pontos: clienteInfo.pontos,
                    cashback: clienteInfo.cashback,
                }));

            } catch (error) {
                console.error('Erro ao buscar informações do cliente:', error);
            }

        } else {
            // Limpar os dados do clienteData caso o CPF não tenha 14 dígitos
            setClienteData((prevData) => ({
                ...prevData,
                pontos: '',
                cashback: '',
            }));
        }
    };


  return (

    <>
    <div className="page-container">
      {/* Barra Superior */}
      <NavbarComp/>
     <br></br>
     <br></br>
     <br></br>
 
    <div className="content">

        <div className="nfc-section">

         <div className='caixa-container-logo'>
         <i className="iconsminds-shop-4 icone-grafico-caixa" ></i>
         </div>
  
          <Form className="caixa-form">
   
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
          <InputMask
            mask="999.999.999-99"
            maskChar=" "
            className="form-control input"
            name="cpf"
            placeholder="Digite o CPF do cliente"
            value={clienteData.cpf}
            onChange={handleInputChange}
            onInput={handleCPFChange}
            required
          />
        </Form.Group>

        {/* Campo de Valor */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
          <Form.Control
            type="text"
            placeholder="R$ 0,00"
            className="input"
            onInput={(event) => mascaraMoeda(event)}
            name="valor"
            value={clienteData.valor}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                                <Form.Select
                                    aria-label="Default select example"
                                    className="input"
                                    value={tipoFaturaSelecionado}
                                    onChange={handleTipoFaturaChange}
                                    required
                                >
                                    <option value="">Tipo de Fatura</option>
                                    <option value="1">Nota Fiscal</option>
                                    <option value="2">Recibo</option>
                                    <option value="3">Numero de Venda</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                                <Form.Control
                                    type="text"
                                    placeholder="Número de venda"
                                    className="input"
                                    value={numeroVenda}
                                    onChange={handleNumeroVendaChange}
                                    required
                                />
                            </Form.Group>



      </Form>

      <div className="actions">

       

         {/*<button onClick={() => showVoucherModal(true)} className="action-btn orange">
        <FontAwesomeIcon icon={faGift} /> Resgatar Voucher
        </button>
*/}
    </div>
    
    </div>

        {/* Área de Caixa Livre */}
        <div className="caixa">
          {preVisualizacao ? (
        <p>{preVisualizacao}</p>
    ) : (
        <h3>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        Insira o CPF para visualizar as informações do cliente.</h3>
    )}
                   {/* Botão Pontuar Condicional */}
    {clienteData.cpf && clienteData.valor && tipoFaturaSelecionado && numeroVenda ? (
        <button onClick={handleAlertaSelecionar} className="action-btn blue">
        <FontAwesomeIcon icon={faStar} /> Pontuar Cliente
    </button>
    ) : clienteData.cpf && clienteData.valor ? (
<button onClick={handleAlertaNumeroVenda} className="action-btn blue">
<FontAwesomeIcon icon={faStar} /> Pontuar Cliente
</button>
    ) : null }

{clienteData.cpf && clienteData.valor ? (
      <button className="action-btn blue botao-cancelar" onClick={handleLimparCaixa}>
        <FontAwesomeIcon icon={faTrash} /> Cancelar
        </button>
    ) : null}

{preVisualizacao ? (
      <button className="action-btn blue botao-cancelar" onClick={setShowEditar}>
        <FontAwesomeIcon icon={faEdit} /> Editar Cliente
        </button>
    ) : null}

    
        </div>
      </div>
     
      {/* Botões de Ação */}
      
    </div>


    <Modal show={showNotificationCompra} onHide={handleClose} className="custom-modal">
        <Modal.Header closeButton className="modal-header-custom">
          <Modal.Title>Resgatar Pontos do Cliente </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <CardModalCaixaCompra
             titulo1="Valor da Compra"
             titulo2="Pontos Atuais"
             icone1="iconsminds-shop-4 icongeral-resgate"
             icone2="iconsminds-diamond icongeral-resgate"
             dinheiro1={0}
             dinheiro2={0}
             valor1={clienteData.valor}
             valor2={(Math.trunc(dadosCliente[0]?.pontos * 100) / 100).toFixed(2)}
              />
               <CardModalCaixaCompra
             titulo1="Pontos Útilizaveis"
             titulo2="Cashback Convertido"
             icone1="iconsminds-financial icongeral-resgate"
             icone2="iconsminds-dollar icongeral-resgate"
             dinheiro1={0}
             dinheiro2={1}
             valor1={(Math.trunc(calcularPontosUtilizados(calcularCashbackPontos()) * 100) / 100).toFixed(2)}
             valor2={(calcularCashbackPontos()).toFixed(2)}
              />
               <CardModalCaixaCompraUnico
             titulo1="Valor com o desconto do Cashback"
             icone1="iconsminds-cash-register-2 icongeral-resgate"
             dinheiro1={1}
             valor1={Math.max(0, clienteData.valorNumber - calcularCashbackPontos()).toFixed(2)}
              />

{/*
          <div className='detalhes-fatura'>
<br></br>
<br></br>
<br></br>
            <h4 className='titulo-valor-total-venda'>Valor com o desconto do Cashback</h4>
            <h1 className='text-valor-total-venda' >R$ </h1>


          
          </div> */ }
<br></br>
          <div className='FotterModal'>
        {/*  <RegraTipoFatura numero={tipoFaturaSelecionado} texto={numeroVenda} />*/ }
            <Button onClick={() => handleCashbackReducao()} className='action-btn blue botao-caixa'><FontAwesomeIcon icon={faMoneyBill} /> Resgatar Cashback</Button>{'  '}
           
            </div>

            
<br></br>
        </Modal.Body>



      </Modal>

    <Modal show={showNotificationCompraSemPonto} onHide={handleClose} className="custom-modal">
        <Modal.Header closeButton className="modal-header-custom">
          <Modal.Title>Pontuar Cliente </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <CardModalCaixaCompra
             titulo1="Valor da Compra"
             titulo2="Pontos Ganho"
             icone1="iconsminds-shop-4 icongeral-resgate"
             icone2="iconsminds-diamond icongeral-resgate"
             dinheiro1={0}
             dinheiro2={0}
             valor1={clienteData.valor}
             valor2={(Math.trunc((clienteData.valorNumber) * 100) / 100).toFixed(2)}
              />
            
               <CardModalCaixaCompraUnico
             titulo1="Total de Pontos Após a Compra"
             icone1="iconsminds-diamond "
             dinheiro1={1}
             valor1={(Math.trunc((dadosCliente[0]?.pontos + clienteData.valorNumber) * 100) / 100).toFixed(2)}
              />

{/*
          <div className='detalhes-fatura'>
<br></br>
<br></br>
<br></br>
            <h4 className='titulo-valor-total-venda'>Valor com o desconto do Cashback</h4>
            <h1 className='text-valor-total-venda' >R$ </h1>


          
          </div> */ }
<br></br>
<br></br>
          <div className='FotterModal'>
            
            <Button  onClick={() => handleAdd()}  className='action-btn blue botao-caixa'><FontAwesomeIcon icon={faStar} /> Pontuar Cliente</Button>
            </div>

            
<br></br>
        </Modal.Body>



      </Modal>





<Modal show={showCadastro} onHide={handleClose} >
<Modal.Header closeButton>
    <Modal.Title>Cadastro Cliente</Modal.Title>
</Modal.Header>
<Modal.Body>
    <CadastroCliente handleClose={handleClose} />
</Modal.Body>
</Modal>

<Modal show={showEditar} onHide={handleClose} >
<Modal.Header closeButton>
    <Modal.Title>Editar Cliente</Modal.Title>
</Modal.Header>
<Modal.Body>
    <EditarCliente cpf={clienteData.cpf}  handleClose={handleClose} />
</Modal.Body>
</Modal>

<Modal show={showVoucher} onHide={handleClose} >
<Modal.Header closeButton>
    <Modal.Title>Resgatar Voucher</Modal.Title>
</Modal.Header>
<Modal.Body>
    <VoucherCliente handleClose={handleClose} />
</Modal.Body>
</Modal>
<Modal show={showHistorico} onHide={handleClose} className='modal-full'>
<Modal.Header closeButton>
    <Modal.Title>Historico Cliente</Modal.Title>
</Modal.Header>
<Modal.Body>

    <Table striped bordered hover>
        <thead>
            <tr>
                <th>Consumidor</th>
                <th>Ação</th>
                <th>Quantidade</th>
                <th>Data</th>
                <th>Agente</th>
                <th>Local</th>
            </tr>
        </thead>
        <tbody>
            {historicos.map((historico) => (
                <tr key={historico.id}>
                    <td>{historico.cliente}</td>
                    <td className={`tipo-${historico.tipo.toLowerCase().replace(' ', '-')}`}>{historico.tipo}</td>
                    <td>{historico.pontos}</td>
                    <td>{format(new Date(historico.at_create), 'dd/MM/yyyy HH:mm:ss')}</td>
                    <td> {historico.usuario}</td>
                    <td>{historico.local}</td>
                    
                </tr>
            ))}
        </tbody>
    </Table>
</Modal.Body>
</Modal>


<button
      onClick={setShowCadastro}
      style={{
        position: 'fixed',
        width: '60px',
        height: '60px',
        bottom: '40px',
        right: '40px',
        backgroundColor: '#25d366',
        color: '#FFF',
        borderRadius: '50%',
        border: 'none',
        textAlign: 'center',
        fontSize: '30px',
        boxShadow: '1px 1px 2px #888',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={faUserPlus} />
    </button>
</>
  );
};

export default Caixa;
