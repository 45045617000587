import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Button, Card } from 'react-bootstrap';
import FotoPerfil from '../../assets/foto-perfil.webp'
import aniversario from '../../assets/aniversario.jpg'
import { Image } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import swal from 'sweetalert';
import DashboardCardsPcliente from '../../components/dashboard/DashboardCardsPcliente'

const isSameWeek = (date1, date2) => {
  const startOfWeek = (date) => {
      const d = new Date(date);
      d.setDate(d.getDate() - d.getDay()); // Ajusta para o início da semana (domingo)
      return d;
  };

  const weekStart = startOfWeek(date2); // Início da semana de hoje
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 6); // Fim da semana (sábado)

  return date1 >= weekStart && date1 <= weekEnd;
};

const fetchClientes = async (cpf, cashbackConfig, setPontosAtuaisCliente, setPreVisualizacaoCaixa, setPreVisualizacao, showCadastroModal) => {
  try {
    const token = sessionStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token}`,
    };

    const response = await axios.get(`${process.env.REACT_APP_URLBK}/v-1/clientes/consulta?documento=${cpf}`,
      { headers }
    );

    var dados = {
      "cpf": cpf,
  }

   
  const showCustomAlert = (title, text, imageSrc) => {
    swal({
        title: title, // Título dinâmico
        text: text,   // Texto dinâmico
        content: {
            element: "img",
            attributes: {
                src: aniversario, // Caminho dinâmico para a imagem
                alt: "Aviso",
                id: "custom-swal-image",
            },
        },
        button: "Entendido!",
        dangerMode: true,
        customClass: {
            popup: 'custom-swal-popup',
        },
    }).then(() => {
        // Adiciona o ID ao modal após ele ser renderizado
        const modal = document.querySelector('.swal2-popup');
        if (modal) {
            modal.id = 'custom-swal-id';
        }
    });
};




    

const responsehistorico = await axios.post(
  `${process.env.REACT_APP_URLBK}/v-1/historico/movimentacao`,
  dados,
  { headers }
);

// Ordena o histórico por ID em ordem decrescente
const historico = responsehistorico.data.sort((a, b) => b.id - a.id);



    if (response.data && response.data.length > 0) {
      const cliente = response.data[0];
      var cashback = cliente.pontos;

      if (cashbackConfig.ativar) {
        cashback = cashback * cashbackConfig.percentual / 100;
      } else {
        cashback = 0;
      }

      setPontosAtuaisCliente({
        pontos: cliente.pontos,
        cashback: cashback
      })
 
      //cliente.dataNascimento = "07/01/1990";

      if (cliente.dataNascimento) {
        const [dia, mes] = cliente.dataNascimento.split('/');
        const aniversario = new Date(new Date().getFullYear(), mes - 1, dia); // Usando o ano atual para a comparação

        const hoje = new Date();
        const amanha = new Date(hoje);
        amanha.setDate(hoje.getDate() + 1);

        // Verifica se o aniversário é ontem
        const ontem = new Date(hoje);
        ontem.setDate(hoje.getDate() - 1);

        if (aniversario.getDate() === hoje.getDate() && aniversario.getMonth() === hoje.getMonth()) {
            showCustomAlert("Hoje é aniversário dele(a)!", "Deseje feliz aniversário!");
        } 
        // Verifica se o aniversário foi ontem
        else if (aniversario.getDate() === ontem.getDate() && aniversario.getMonth() === ontem.getMonth()) {
            showCustomAlert("Ontem foi o aniversário dele(a)!", "Desculpe, você perdeu o aniversário, mas ainda dá tempo de desejar felicidades!");
        }
        // Verifica se o aniversário é amanhã
        else if (aniversario.getDate() === amanha.getDate() && aniversario.getMonth() === amanha.getMonth()) {
            showCustomAlert("Amanhã é aniversário dele(a)!", "Prepare-se para desejar feliz aniversário amanhã!");
        }
        // Verifica se o aniversário é nesta semana
        else if (isSameWeek(aniversario, hoje)) {
            showCustomAlert("Esta é a semana do aniversário dele(a)!", "Aproveite para desejar feliz aniversário durante essa semana!");
        }
    }


    


      setPreVisualizacaoCaixa(
      <div className="invoice">
         <header className="header">
       
       <div className="header--logo">
      
       <p className="logo--address"><h2>{cliente.nome}</h2>
       <h4 className=""><strong>E-mail:</strong> {cliente.email}</h4>
       
       </p>
         
       </div>
       <div className="header--invoice">
       <Image src={FotoPerfil} width="100px" />
        
      
       </div>
       
     </header>
     
        
       
        <section className="description">
          {historico.length > 0 ? (
        <DashboardCardsPcliente 
        icon="iconsminds-male"
        title1="Pontos"
        title2="Cashback"
        currentValue1={(Math.trunc(cliente.pontos * 100) / 100).toFixed(2)}
        currentValue2={(Math.trunc(cashback * 100) / 100).toFixed(2)}
    />
      ) : (
        <h3 className="texto-nao-computado"><strong>Não foram computados pontos!</strong></h3>
      )}
        </section>

        {historico.length > 0 && (
        <table className="items--table" striped  hover variant="dark">
          <thead>
            <tr>
              <th>Empresa</th>
              <th>Data</th>
              <th>Vendedor</th>
              <th>Tipo</th>
              <th>Pontos</th>
              <th>Cashback</th>
            </tr>
          </thead>
          <tbody>
         

          {historico.slice(0, 5).map((item, index) => (
              <tr key={index}>
                <td>{item.empresa}</td>
                <td>{new Date(item.at_create).toLocaleString()}</td>
                <td>{item.vendedor}</td>
                <td>{item.tipo}</td>
                { /*<td>{item.expiracao ? new Date(item.expiracao).toLocaleString() : "Não Expira"}</td> */}
                 <td>{parseFloat(item.pontos).toFixed(2)}</td> 
                 <td>{parseFloat(item.cashback).toFixed(2)}</td> 
              </tr>
            ))}
            

            
          </tbody>
        </table>
      )}
       
        
      </div>
      )

     
    } else {
      // Caso não haja dados de cliente na resposta
      console.error('Nenhum cliente encontrado.');
      setPreVisualizacao(<div align="center"> 
        <h2>Cliente não cadastrado</h2>
         <div className='caixa-container-buttons'>
        <Card onClick={showCadastroModal}>
          <Card.Title className='bg-blue clicavel'><FontAwesomeIcon icon={faUserPlus} size='2x' color='white' /></Card.Title>
          <Button >Cadastrar</Button>
        </Card>
      </div></div>)
      setPreVisualizacaoCaixa(<div align="center"> 
        <h2>Cliente não cadastrado</h2>
         <div className='caixa-container-buttons'>
        <Card onClick={showCadastroModal}>
          <Card.Title className='bg-blue clicavel'><FontAwesomeIcon icon={faUserPlus} size='2x' color='white' /></Card.Title>
          <Button >Cadastrar</Button>
        </Card>
      </div></div>)
    }
  } catch (error) {
    console.error('Erro ao buscar clientes:', error);
  }
};

export default fetchClientes;