import axios from 'axios';

const compraAddResgatar = async (clienteData) => {
    try {
        const dados = {
            "cpf": clienteData.cpf,
            "valor": clienteData.valor,
            "numeroVenda": clienteData.numeroVenda,
            "tipoFaturaSelecionado": clienteData.tipoFaturaSelecionado
        };

        const token = sessionStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        };

        const response = await axios.post(
            `${process.env.REACT_APP_URLBK}/v-1/clientes/comprar_clientes`,
            dados,
            { headers }
        );

        return response;
    } catch (error) {
        console.error("Erro na requisição:", error);
        throw error; 
    }
};

export default compraAddResgatar;
