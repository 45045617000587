import React, { useEffect, useState } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import axios from 'axios';

function HistoricoCliente({ cliente }) {
  const [historico, setHistorico] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  useEffect(() => {
    const fetchHistorico = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        };

        const dados = {
          cpf: cliente.cpf,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_URLBK}/v-1/historico/movimentacao`,
          dados,
          { headers }
        );

        // Ordena o histórico por ID em ordem decrescente
        const sortedHistorico = response.data.sort((a, b) => b.id - a.id);
        setHistorico(sortedHistorico);
      } catch (error) {
        console.error('Erro ao buscar histórico:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHistorico();
  }, [cliente.cpf]); // O useEffect será executado sempre que cliente.cpf mudar

  const totalPages = Math.ceil(historico.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedHistorico = historico.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>

      <Card className="cardPontosDistribuidos">
        <Card.Title className="cadastroNovoscliente-card-title">
          <h4>Histórico</h4>
        </Card.Title>
        <Card.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <span className="loader"></span>
            </div>
          ) : (
            <>
              <div style={{ overflowX: 'auto', width: '100%' }}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Empresa</th>
                      <th>Vendedor</th>
                      <th>Data</th>
                      <th>Expira em</th>
                      <th>Pontos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedHistorico.map((item, index) => (
                      <tr key={index}>
                        <td>{item.empresa}</td>
                        <td>{item.vendedor}</td>
                        <td>{new Date(item.at_create).toLocaleString()}</td>
                        <td>
                          {item.expiracao
                            ? new Date(item.expiracao).toLocaleString()
                            : 'Não Expira'}
                        </td>
                        <td>{parseFloat(item.pontos).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              {/* Paginação */}
              <div className="d-flex justify-content-center">
                <ul className="pagination">
                  {/* Botão Anterior */}
                  <li
                    className={`page-item ${
                      currentPage === 1 ? 'disabled' : ''
                    }`}
                  >
                    <Button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Anterior
                    </Button>
                  </li>

                  {/* Lógica para limitar a exibição a 6 páginas */}
                  {Array.from({ length: Math.min(6, totalPages) }).map(
                    (_, index) => {
                      const startPage = Math.max(1, currentPage - 3);
                      const endPage = Math.min(totalPages, startPage + 5);
                      const page = startPage + index;

                      if (page > totalPages) return null;

                      return (
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? 'active' : ''
                          }`}
                        >
                          <Button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </Button>
                        </li>
                      );
                    }
                  )}

                  {/* Botão Próxima */}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? 'disabled' : ''
                    }`}
                  >
                    <Button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Próxima
                    </Button>
                  </li>
                </ul>
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default HistoricoCliente;
