// DashboardCards.js
import React from 'react';
import './CardModalCaixaCompra.css';
import "../Estrutura/iconsmind-s/css/iconsminds.css";

const DashboardCardPcliente = ({ icon, title, count, dinheiro }) => {
    return (
        <li className="glide__slide">
            <a href="#" className="card">
                <div className="card-body text-center">
                    <i className={`${icon} icongeral`}></i>
                    <p className="card-text mb-0">{title}</p>
                    <p className="lead text-center">
                    {dinheiro == 0 ? '' : "R$ "}{count}
                    </p>
                </div>
            </a>
        </li>
    );
};


const CardModalCaixaCompra = ({titulo1,titulo2,icone1,icone2,valor1,valor2, dinheiro1, dinheiro2}) => {
    const cardsData = [
        { icon: icone1, title: titulo1, count: valor1, dinheiro: dinheiro1 },
        { icon: icone2, title: titulo2, count: valor2, dinheiro: dinheiro2 },
    ];

    return (
        <div className="icon-cards-row">
            <div className="glide dashboard-numbers">
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        {cardsData.map((card, index) => (
                            <DashboardCardPcliente key={index} {...card} />
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};


const CardModalCaixaCompraUnico = ({titulo1,icone1,valor1,dinheiro1}) => {
    const cardsData = [
        { icon: icone1, title: titulo1, count: valor1, dinheiro: dinheiro1 },
    ];

    return (
        <div className="icon-cards-row">
            <div className="glide dashboard-numbers">
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        {cardsData.map((card, index) => (
                            <DashboardCardPcliente key={index} {...card} />
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export { CardModalCaixaCompraUnico };
export default CardModalCaixaCompra;
