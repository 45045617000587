import React from 'react';
import { Image } from 'react-bootstrap';
import './Navbar.css'; // Arquivo CSS separado
import Logo from '../../assets/logo.png'
import Avatar from '../../components/Avatar/Avatar'
import MenuMobile from './MenuMobile'

const Navbar = () => {
  return (
    <nav className="navbar fixed-top">
      <div className="d-flex align-items-center navbar-left" >
      
      <MenuMobile />

        
      </div>

    
      <Image className='logo-center' src={Logo}/>
 

      <div className="navbar-right">
        

        {/* User Menu */}
        <Avatar/>
      </div>
    </nav>
  );
};

export default Navbar;
